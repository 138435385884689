import React, { useState } from "react";
import "./FilterConverter.css";

const FilterConverter = () => {
  const [filterInput, setFilterInput] = useState("");
  const [jsonOutput, setJsonOutput] = useState("");

  const handleConvert = async () => {
    try {
      const response = await fetch("/api/convert", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: filterInput,
      });
  
      if (response.ok) {
        const jsonResponse = await response.json();
        setJsonOutput(JSON.stringify(jsonResponse, null, 4));
      } else {
        setJsonOutput("Error converting filter");
      }
    } catch (error) {
      console.error("Error:", error);
      setJsonOutput("Error converting filter");
    }
  };  

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(jsonOutput);
      alert("JSON copied to clipboard!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error copying JSON to clipboard");
    }
  };

  return (
    <div>
      <div>
        <img className="w-32 mx-auto mb-10" src="./logo192.png" alt="Logo" style={{objectFit: "contain"}} />
        <h1 className="text-white text-md text-center font-bold">autodl-irssi to autobrr filter converter</h1>
      </div>
      <div className="textareas-container">
        <div className="textarea-wrapper">
          <h3 className='text-md text-white'>Input:</h3>
          <textarea
            className="focus:outline-none focus:ring-2  focus:ring-blue-400"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            rows="10"
            cols="70"
            placeholder="Enter autodl-irssi filter here..."
          ></textarea>
          <button className="text-white" onClick={handleConvert}>Convert to JSON</button>
        </div>
        <div className="textarea-wrapper">
          <h3 className='text-md text-white'>Output:</h3>
          <textarea
            className="focus:outline-none focus:ring-2  focus:ring-blue-400"
            value={jsonOutput}
            readOnly
            rows="10"
            cols="70"
            placeholder="Converted JSON will appear here..."
          ></textarea>
          <div className="float-right">
            <button className="text-white" onClick={handleCopy}>Copy to Clipboard</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterConverter;
