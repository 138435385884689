import React from "react";
import FilterConverter from "./FilterConverter";
import "./App.css";

function App() {
  return (
    <div className="App">
      <FilterConverter />
    </div>
  );
}

export default App;
